.App {
    text-align: left;
}

.App-header {
    background-color: #313233;
    background: radial-gradient(circle at 50% 70px, #393956 0%, #161627 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: max(min(0.8vw, 0.5em), 1.2rem);
    color: white;
}

.search-results {
    width: 800px;
}

.search-results textarea {
    display: block;
    width: 100%;
    height: 600px;
}

.inputs {
    width: 800px;
}

.inputs input {
    display: block;
    margin: 0.5rem 0 1rem 0;
    padding: 0.5rem;
    border-radius: 0.125rem 0.25rem;
    border-width: 4px;
    background-color: hsl(222, 20%, 95%);
}

.inputs input:invalid {
    background-color: hsl(25, 93%, 80%);
    border-color: hsl(14, 73%, 40%);
}

.form-error {
    background-color: #151515;
    border-radius: 0.125rem 0.25rem;
}

.stats {
    width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}